import React from 'react';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';

const StripeCheckout: React.FC<any> = (props) => {
    return (
        <EmbeddedCheckoutProvider
            stripe={props.stripePromise}
            options={{ clientSecret: props.clientSecret }}
        >
            <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
    );
};

export default StripeCheckout
