import { all, call, put, select, delay, take, fork } from "redux-saga/effects";
import apiService from "@/services/bmApi";
import {
  getFormSyncErrors,
  getFormValues,
  submit,
  clearSubmitErrors,
  touch,
} from "redux-form";
import { AnyAction } from "redux";
import { push, replace } from "connected-react-router";

import api from "@/api/services";
import { ConfigKeys } from "@/misc/constants";
import { toDate } from "@/misc/datetime";
import * as actions from "@/actions";

import {
  ServiceType,
  QuantityType,
  CustomFieldValueType,
  PromoCode,
  TimeType,
  Configuration,
  Article,
} from "@/types";
import { KeycloakInstance } from "keycloak-js";
import keycloak, { keycloakConfig } from "@/keycloak";
import { History } from "history";
import qs from "qs";
import { BACK_URI, LAST_PATHNAME_STORAGE_KEY } from "@/providers/IdentityProvider";
import { getTranslate, scrollToElement } from "@/misc/common";
import readConfigurationProperty from "@/misc/readConfigurationProperty";
import { RootState } from "@/reducers";
import { AxiosResponse } from "axios";
import { request } from "@/api/endpoint";
import { ApplicationState, persistor, storage } from "@/store";
import { PayloadAction } from "@reduxjs/toolkit";
import { addConfiguration } from "@/reducers/configuration";
import { definitions } from "@/apitypes";
import { destroy, setError } from "@/reducers/flexibleHours";

const API_LIMIT_TIMES = 8000;
const DURATION_OF_MESSAGE = 5000;
const FORWARD_ONLY = "forwardOnly";

const settle = (fn:any, ...args: any) =>
  call(function*() {
    try {
      // @ts-ignore
      return { status: "fulfilled", value: yield call(fn, ...args) };
    } catch (err) {
      return { status: "rejected", reason: err };
    }
  });

interface StartAuthenticateArgs {
  type: string;
}

export function setParentFullscreen(isFullscreen = false, referer: string) {
  if (window?.parent && referer) {
    window.parent.postMessage(
      {
        fullscreen: isFullscreen,
      },
      referer
    );
  }
}

export function resizeParentIframe(size: number | void) {
  if (!("parentIFrame" in window)) {
    return;
  }

  if (typeof size === "number") {
    // @ts-ignore
    parentIFrame.autoResize(false);
    // @ts-ignore
    parentIFrame.size(size);
  } else {
    // @ts-ignore
    parentIFrame.autoResize(true);
  }
}

let refreshTokenIntervalId: number;


export function* resetParentConfig() {
  const configuration: ApplicationState["configuration"]["data"] = yield select(
    (state: ApplicationState) => state.configuration.data
  );
  
  const targetOrigin = configuration?.targetOrigin
      ? configuration?.targetOrigin
      : configuration?._targetOrigin as string;

  setParentFullscreen(false, targetOrigin);
  resizeParentIframe();
}

interface AuthenticateWithKeyCloakArgs {
  type: string;
  payload: any;
}

export function* fetchServices() {
  const configuration: ApplicationState["configuration"]["data"] = yield select(
    (state: ApplicationState) => state.configuration.data
  );
  const companyId = configuration.company;

  yield put({
      type: 'FETCH_SERVICES_REQUEST',
  });

  const params = configuration.bookLayout === ConfigKeys.BOOK_LAYOUT_RESOURCE_BASED ? { IncludeResources: true } : {};

  try {
      const response: {[key in string]: any} = yield call(api.getServicesList, companyId, params);

      // Setup times object
      yield all(
          response.Results.map((service: ServiceType) => put({ type: 'LOAD_TIMES', service }))
      );

      let services: definitions['Service'][] = [...response.Results];
      
      if (configuration["priceViewTypeId"]) {
        services.forEach(
          (s) => (s.PriceViewTypeId = Number(configuration["priceViewTypeId"]))
        );
      }

      // Save services
      yield put({
          type: 'FETCH_SERVICES_SUCCESS',
          services,
      });

      if (configuration[ConfigKeys.BOOK_LAYOUT] === ConfigKeys.BOOK_LAYOUT_TIME_BASED) {
          yield put({ type: 'FETCH_SERVICES_TIMES' });
      }
  } catch (error) {
      yield put({
          type: 'FETCH_SERVICES_FAILURE',
          error: error,
      });
  }
}

export function* fetchCompany() {
  try {
    const configuration: ApplicationState["configuration"]["data"] =
      yield select((state: ApplicationState) => state.configuration.data);
    const companyId = configuration.company;
    const response: {[key in string]: any} = yield call(api.getCompany, companyId);

    yield put(actions.fetchCompanyAsync.success(response.Results[0]));
  } catch (error: any) {
    yield put(actions.fetchCompanyAsync.failure(error));
  }
}

export function* fetchCompanyService(action: any) {
  let booking: RootState['booking'] = yield select((state) => state.booking);
  const configuration: ApplicationState["configuration"]["data"] = yield select(
    (state: ApplicationState) => state.configuration.data
  );

  const companyId = configuration.company;
  const time = booking.time;

  let service = action.service;

  try {
    const response: {[key in string]: any} = yield call(
      // @ts-ignore
      api.getCompanyService,
      companyId,
      service,
      time,
      { IncludeSchedules: true }
    );
    service = response.Results[0];
  } catch (error) {
    console.error({ error });
  }

  // Setup booking custom fields
  if (service.BookingCustomFields && service.BookingCustomFields.length > 0) {
    yield call(setupBookingCustomFields, {
      customFields: service.BookingCustomFields,
      service,
    });
  }

  yield put({
    type: "SAVE_FINAL_SERVICE",
    service,
  });

  booking = yield select((state) => state.booking);

  if (booking.quantities.length > 0) {
    yield put(actions.calculatePriceAsync.request());
  }
}

export function* fetchTimes() {
  try {
    const configuration: ApplicationState["configuration"]['data'] = yield select(
      (state: ApplicationState) => state.configuration.data
    );
  
    const booking: ApplicationState['booking'] = yield select((state: any) => state.booking);
    const flexibleDuration: ApplicationState['booking'] = yield select((state: ApplicationState) => state.flexibleHours?.entity?.value);
  
    const serviceId = booking.service?.Id;
  
    if(!serviceId) {
      throw new Error('No selected service id persisted');
    }
  
    const services: ApplicationState['services']['data'] = yield select((state: ApplicationState) => state.services.data);
    const service = services.find(s => s.Id == serviceId);
    const companyId = configuration.company;
  
    yield put({
      type: "LOAD_TIMES",
      service,
    });
  
    const response: {[key in string]: any} = yield call(
      // @ts-ignore
      api.getTimesList,
      companyId,
      booking,
      service,
      configuration,
      flexibleDuration
    );
  
    yield put({
      type: "SAVE_TIMES",
      single: response.TimesFreeTextSingle,
      multiple: response.TimesFreeTextMultiple,
      service,
      times: response.Times,
    });
  
    if (configuration[ConfigKeys.SHOW_NEXT_AVAILABLE_TIME]) {
      if (
        response.Times.length === 0 ||
        response.Times.every((time: TimeType) => time.Free === 0)
      ) {
        yield call(fetchNextAvailableTime);
      }
    }
  } catch (error) {
    console.error(error);
    yield put(actions.fixLoadingTooLong.request());
  }
}

export function* fetchFlexibleTimes() {
  try {
    const configuration: ApplicationState["configuration"]['data'] = yield select(
      (state: ApplicationState) => state.configuration.data
    );
  
    const booking: ApplicationState['booking'] = yield select((state: any) => state.booking);
    const flexibleDuration: ApplicationState['booking'] = yield select((state: ApplicationState) => state.flexibleHours?.entity?.value);
  
    const serviceId = booking.service?.Id;
  
    if(!serviceId) {
      throw new Error('No selected service id persisted');
    }
  
    const services: ApplicationState['services']['data'] = yield select((state: ApplicationState) => state.services.data);
    const service = services.find(s => s.Id == serviceId);
    const companyId = configuration.company;
  
    yield put({
      type: "LOAD_TIMES",
      service,
    });
  
    const response: {[key in string]: any} = yield call(
      // @ts-ignore
      api.getTimesList,
      companyId,
      booking,
      service,
      configuration,
      flexibleDuration
    );
  
    yield put({
      type: "SAVE_TIMES",
      single: response.TimesFreeTextSingle,
      multiple: response.TimesFreeTextMultiple,
      service,
      times: response.Times,
    });
  
    if (configuration[ConfigKeys.SHOW_NEXT_AVAILABLE_TIME]) {
      if (
        response.Times.length === 0 ||
        response.Times.every((time: TimeType) => time.Free === 0)
      ) {
        yield call(fetchNextAvailableTime);
      }
    }
  } catch (error: any) {
    console.error(error);
    yield put(setError(error._error))

  }
}

export function* fetchTimesWatcher(action: PayloadAction) {
  if(action.type === 'FETCH_FLEXIBLE_TIMES') {
    yield call(fetchFlexibleTimes);
  } else {
    yield call(fetchTimes);
  }
}

export function* fetchServicesTimes(args?: any) {
  const configuration: ApplicationState["configuration"]["data"] = yield select(
    (state: ApplicationState) => state.configuration.data
  );
  const companyId = configuration.company;
  const booking: RootState['booking'] = yield select((state: any) => state.booking);

  try {
    const services: RootState['services'] = yield select((state: any) => state.services);
    let timesCount = 0;
    let i = 0;

    while (i < services.data.length && timesCount <= API_LIMIT_TIMES) {

      yield put({
        type: "LOAD_TIMES",
        service: services.data[i],
      });

      const servicePair = [services.data[i], services.data[i + 1]].filter(
        (s) => s
      );

      const calls = servicePair.map((service: ServiceType) =>
        settle(api.getTimesList, companyId, booking, service, configuration)
      );

      const responses: AxiosResponse[] = yield all(calls);

      for (let index = 0; index < responses.length; index++) {
        const response = responses.filter((s: any) => s.status !== 'rejected').map((s: any) => s.value)[index];

        if (response) {
          yield put({
            type: "SAVE_TIMES",
            single: response.TimesFreeTextSingle,
            multiple: response.TimesFreeTextMultiple,
            service: services.data[i],
            times: response.Times,
          });
          timesCount = timesCount + response.Times.filter((t: TimeType) => t.Free).length;
        }
        i = i + 1 + responses.filter((s: any) => s.status.rejected).length;
      }
    }

    if (timesCount > API_LIMIT_TIMES) {
      const id = Date.now();
      yield put({
        type: "SET_ERROR_MESSAGE",
        payload: {
          id,
          value:
            "We are showing only 50 time slots because this is the API limit.",
          messageId: "API_LIMIT_TIMES",
        },
      });
      yield delay(DURATION_OF_MESSAGE);
      yield put({
        type: "CLEAR_MESSAGE",
        payload: { id },
      });
    } else if (timesCount === 0) {
      yield call(fetchNextAvailableTime);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* fetchNextAvailableTime() {
  try {
    let configuration: ApplicationState["configuration"]["data"] = yield select(
      (state: ApplicationState) => state.configuration.data
    );
    const services: ApplicationState['services'] = yield select((state: ApplicationState) => state.services);
    const flexibleDuration: number | undefined = yield select((state: ApplicationState) => state.flexibleHours.entity?.value);
    let serviceId = configuration.selectedService;

    if(!serviceId) {
      serviceId = yield select((s: ApplicationState) => s?.booking?.service?.Id)
    }

    const service = services.data.find(s => s.Id == serviceId);
    
    let booking: RootState['booking'] = yield select((state: any) => state.booking);
    let companyId = configuration.company;
  
    const response: {[key in string]: any} = yield call(
      // @ts-ignore
      api.getNextAvailableTime,
      companyId,
      booking,
      service,
      configuration,
      flexibleDuration
    );
  
    if (response.Times && response.Times.length > 0) {
      yield put({
        type: "SAVE_NEXT_AVAILABLE_TIME",
        time: response.Times[0],
        service,
      });
    }
  } catch (error) {
    console.error(error)
  }
}

export function* selectResourceSaga(action: PayloadAction<string>) {
  try {
    yield call(fetchTimes);
  } catch (error) {
    console.error(error);
  }
}

export function* selectPrevNextCalendar(action: AnyAction) {
  const configuration: ApplicationState["configuration"]["data"] = yield select(
    (state: ApplicationState) => state.configuration.data
  );

  if (
    configuration[ConfigKeys.BOOK_LAYOUT] ===
    ConfigKeys.BOOK_LAYOUT_SERVICE_BASED
  ) {
    yield call(fetchTimes);
  }
  if (
    configuration[ConfigKeys.BOOK_LAYOUT] ===
    ConfigKeys.BOOK_LAYOUT_RESOURCE_BASED
  ) {
    yield call(fetchTimes);
  }
  if (
    configuration[ConfigKeys.BOOK_LAYOUT] === ConfigKeys.BOOK_LAYOUT_TIME_BASED
  ) {
    yield call(fetchServicesTimes);
  }
}

export function* selectNextAvailableTime(action: AnyAction) {
  yield put({
    type: "CHANGE_NAVIGATION_DATE",

    date: action.date,
  });

  yield call(fetchTimes);
}

function* updateServiceWithResource(action: PayloadAction<number>) {
  const serviceId = action.payload;
  let companyId: string = yield select((state: any) => state.company?.data?.Id);
  try {
    const response: {[key in string]: any} = yield call(api.getServicesList, companyId, {
      IncludeResources: true,
      ServiceId: serviceId,
    });

    yield put({
      type: "UPDATE_SERVICE_WITH_RESOURCE",
      service: response.Results[0],
    });
  } catch (error) {
    yield put({
      type: "FETCH_SERVICES_FAILURE",
      error: error,
    });
  }
}

// 
export function* selectService(action: PayloadAction<number>) {
  const flexibleHours: ApplicationState['flexibleHours'] = yield select((s: ApplicationState) => s.flexibleHours);
  if(flexibleHours.serviceId && (flexibleHours.serviceId !== action.payload)) {
    yield put(destroy())
  }

  yield call(updateServiceWithResource, action);
  yield call(fetchTimes);
}

export function* selectTime(action: AnyAction) {
  // Select correct resource if we use resource based layout. I.e. resource is selected based on time
  // instead of times being presented based on what resource you have chosen.
  if (action.resourceType && action.resource) {
    yield put(
      actions.selectResource({
        service: action.service,
        resourceType: action.resourceType,
        resource: action.resource,
      })
    );
  }

  yield put(push(`/services/${action.service.Id}/confirm`, ''));
  yield call(fetchCompanyService, { service: action.service });
}

export function* resetForm() {
  yield put({ type: "RESET_CONFIG" });
  yield put({ type: "CLEAR_SERVICES" });
  yield put({ type: "CLEAR_BOOKINGS" });

  yield call(fetchCompany);
  yield call(fetchServices);
}

let clickedTimes = 0;
export function* selectTimesDay(action: AnyAction) {
  yield put({
    type: "SAVE_SELECTED_DAY",
    day: action.day,
  });

  if (
    clickedTimes === 0 &&
    !readConfigurationProperty("preventAutoscroll", false)
  ) {
    clickedTimes++;
    const timesEl = window?.bookingAppContainer?.querySelector("#times");
    scrollToElement({
      element: timesEl as HTMLElement,
      scrollOptions: { behavior: "smooth", block: "center" },
    });
  }
}

export function* setupBookingCustomFields(action: {
  customFields: Array<CustomFieldValueType>;
  service: ServiceType;
}) {
  let customFields = action.customFields;

  // Apply default values as real initial values
  customFields.forEach((customField) => {
    customField.Value = customField.DefaultValue;
  });

  yield put({
    type: "SAVE_CUSTOM_FIELDS",
    customFields: customFields,
    service: action.service,
  });
}

interface LogoutArg extends AnyAction {
  payload: KeycloakInstance;
}
export function* logout(action: LogoutArg) {
  try {
    window.clearInterval(refreshTokenIntervalId);
    yield put({ type: "CLEAR_USER" });
    const configuration: Configuration = yield select(state => state?.configuration);
    const targetOrigin = configuration?.targetOrigin
      ? configuration?.targetOrigin
      : configuration?._targetOrigin;
      
    const searchParsed = qs.parse(window.location.search);
    delete searchParsed.success;

    const params = new URLSearchParams(window.location.search);
    params.delete('session_state');
    params.delete('code');
    params.delete('state');

    window.location.search = params.toString();
    
    if (window.location !== window.parent.location && action.payload) {
      const url = new URL(window.location.href);
      url.searchParams.delete("success");
      yield put(
        replace({
          search: url.search,
        })
      );
      yield clearSession(keycloakConfig.clientId, keycloak.refreshToken as string);
      
      yield put({ type: "CLEAR_AUTHENTICATION"});
      yield put({ type: "CLEAR_USER"});
      window.parent.postMessage({ logout: true }, targetOrigin || '*');
    } else {
      yield clearSession(keycloakConfig.clientId, keycloak.refreshToken as string);
      yield put({ type: "CLEAR_AUTHENTICATION"});
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getUser(action: { authentication: any }) {
  yield put({
    type: "LOAD_USER",
  });

  try {
    const response: {[key in string]: any} = yield call(api.getUser);
    yield put({
      type: "SAVE_USER",
      user: response,
    });
  } catch (error) {
    yield put({ type: "FAIL_USER" });
  }
}

export function* book(action: AnyAction) {
  const booking: RootState['booking'] = yield select((state: any) => state.booking);
  const prices: ApplicationState['prices']['data'] = yield select((s: ApplicationState) => s.prices.data);
  const company: ApplicationState['company']['data'] = yield select((s: ApplicationState) => s.company.data);
  const authentication: ApplicationState['authenticate'] = yield select((state: any) => state.authenticate);
  const configuration: ApplicationState["configuration"]["data"]= yield select((state: ApplicationState) => state.configuration.data);
  const form: {[key in string]: any} = yield select((state: any) => state.form);
  const promoCodes: RootState['promoCodes']['data'] = yield select((state: any) => state.promoCodes.data);
  const service = booking.finalService;
  const companyId: string = yield select((state: any) => state.company.data.Id);
  const localizeState: {[key in string]: string} = yield select((state: RootState) => state.localize);
  const translate = getTranslate(localizeState);

  if (!service) {
    console.error("No final service found. Aborting...");
    return false;
  }

  // Validate custom fields
  yield put(clearSubmitErrors("customFields"));
  yield put(submit("customFields"));
  const customFieldsErrors: {[key in string]: any} = yield select((state: any) =>
  getFormSyncErrors("customFields")(state)
  );
  
  const customFieldsCustomerFormErrors: {[key in string]: any} = yield select((state: any) =>
    getFormSyncErrors("customerForm")(state)
  );

  let customFieldFormErrors = ''
  if (Object.keys(customFieldsErrors).length > 0) {
    customFieldFormErrors  = Object.values(customFieldsErrors).join("\n");
  }

  if (
    customFieldsCustomerFormErrors["CustomerCustomFields"] &&
    Object.keys(
      customFieldsCustomerFormErrors["CustomerCustomFields"]
    ).length > 0
  ) {
    customFieldFormErrors = `${customFieldFormErrors} \n ${Object.values(
      customFieldsCustomerFormErrors["CustomerCustomFields"]
    ).join("\n")}`;
  }
  
  if(customFieldFormErrors) {
    yield put({
      type: "FORM_ERROR",
      payload: { _error: customFieldFormErrors },
    });
    return;
  }

  const hasMultiplePrices = service.Prices && service.Prices.length > 1;
  const hasGroupBooking =
    service.GroupBooking.Active &&
    service.GroupBooking.Min !== service.GroupBooking.Max;
  const hasMultipleResource =
    service.MultipleResource.Active &&
    service.MultipleResource.Min !== service.MultipleResource.Max;
  const totalQuantity = booking.quantities
    .map((quantity: QuantityType) => quantity.Quantity)
    .reduce((a: number, b: number) => {
      return a + b;
    }, 0);

  // Validate that spots have been chosen
  if (hasGroupBooking || hasMultipleResource) {
    if (totalQuantity === 0) {
      yield put({
        type: "FORM_ERROR",
        payload: { _error: translate('formError.selectASeat') },
      });
      return;
    }
  } else if (hasMultiplePrices) {
    if (booking.quantities.length === 0) {
      yield put({
        type: "FORM_ERROR",
        payload: { _error: translate('formError.chooseAPrice') },
      });
      return;
    }
  }

  // Validate that we know who the customer is before booking
  const supportsContactInformationBooking =
    configuration[ConfigKeys.BOOK_METODS].indexOf(
      ConfigKeys.BOOK_METODS_CONTACT_INFORMATION
    ) > -1;
  const supportsLoginBooking =
    configuration[ConfigKeys.BOOK_METODS].indexOf(
      ConfigKeys.BOOK_METODS_LOGIN
    ) > -1 ||
    configuration[ConfigKeys.BOOK_METODS].indexOf(
      ConfigKeys.BOOK_METODS_LOGIN_FACEBOOK
    ) > -1;

  if (supportsLoginBooking && !supportsContactInformationBooking) {
    if (!authentication.isLoggedIn) {
      yield put({
        type: "FORM_ERROR",
        payload: { _error: translate('formError.youMustLogin') },
      });
      return;
    }
  }

  const customerFormFieldsErrors: {[key in string]: any} = yield select((state: any) =>
    getFormSyncErrors("customerForm")(state)
  );
  if (customerFormFieldsErrors && customerFormFieldsErrors.Customer) {
    console.error({ customerFormFieldsErrors });
    yield put({
      type: "FORM_ERROR",
      payload: {
        _error: Object.values(customerFormFieldsErrors.Customer).join("\n"),
      },
    });
    return;
  }

  // Convert freefields to correct syntax
  let customFields: any = [];
  const customFieldsValues: {[key in string]: any} = yield select((state: any) =>
    getFormValues("customFields")(state)
  );
  if (customFieldsValues) {
    customFields = Object.keys(customFieldsValues).map((customValueKey) => {
      return {
        Id: customValueKey.replace("id-", ""),
        Value: customFieldsValues[customValueKey],
      };
    });
  }

  try {
    yield put({
      type: "LOAD_BOOKING",
      service,
    });

    const { Customer, CustomerCustomFields, PinCode } =
      form.customerForm.values;

      // Add to queue if fully booked
      if(booking.time?.Free === 0 && booking.service?.EnableBookingQueue) {
        const response: Awaited<ReturnType<typeof api.addUserToQueue>> = yield api.addUserToQueue({ 
          CompanyId: companyId,
          Customer,
          From: booking?.time?.From,
          To: booking?.time?.To,
          ServiceId: service.Id,
          // @ts-ignore
          Quantities: booking.quantities,
        })

        yield put({
          type: "ADD_TO_QUEUE",
          bookingUserQueue: response.BookingUserQueue
        });
        
        yield put(push(`/services/${service.Id}/addedtoqueue`, ''));
      } else {
        const state: ApplicationState = yield select(s => s);
        const bookFormValues: any = getFormValues('bookForm')(state);
        const agreedToSubscribe: boolean = !!bookFormValues['_subscribe'];
        
        // @ts-ignore add types
        const sendCustomerInformationToExternalProviders = !!state.company.data?.SystemSettings?.SendCustomerInformationToExternalProviders

        const response: {[key in string]: any} = yield call(api.book, {
          CompanyId: companyId,
          Customer: {
            ...Customer,
            SubscribedToNewsletter: agreedToSubscribe
          },
          ...(action.payload.PaymentOption ? { PaymentOption: action.payload.PaymentOption } : {}),
          CustomerCustomFields: CustomerCustomFields
            ? Object.keys(CustomerCustomFields)
                .filter((key) => CustomerCustomFields[key])
                .map((key) => ({
                  Id: key.slice(1),
                  Value: CustomerCustomFields[key],
                }))
            : null,
          From: booking?.time?.From,
          To: booking?.time?.To,
          CustomFields:
            customFields && customFields.length > 0 ? customFields : undefined,
          PinCode,
          Quantities: booking.quantities,
          RebateCodeIds: promoCodes.map((promoCode: PromoCode) => promoCode.Id),
          Resources: Object.keys(booking.resources).map((resourceTypeId) => ({
            ResourceTypeId: resourceTypeId,
            ResourceId: booking.resources[resourceTypeId],
          })),
          ServiceId: booking?.service?.Id,
          subscribed: agreedToSubscribe
        });

        yield put({
          type: "SAVE_BOOKING",
          booking: response,
          service,
          prices,
          company,
          ...(sendCustomerInformationToExternalProviders
            ? {
                email: Customer.Email,
                firstName: Customer.Firstname,
                lastName: Customer.Lastname,
                phoneNumber: Customer.Phone,
                color: "#c792ea"
              }
            : {}),
        });
        yield put(push(`/services/${service.Id}/completed`, ''));
      }
  } catch (error) {
    yield put({ type: "FAIL_BOOKING", service });
    yield put({ type: "FORM_ERROR", payload: error });
  }
}

export function* createCheckout(action: AnyAction) {
  try {
    const response: {[key in string]: any} = yield call(api.createCheckout, action.payload);

    yield put({ type: "CREATE_CHECKOUT_SUCCESS", payload: response });
  } catch (error) {
    yield put({ type: "CREATE_CHECKOUT_FAILURE", payload: error });
  }
}

export function* applyPromoCode(
  action: ReturnType<typeof actions.applyPromoCodeAsync.request>
) {
  try {
    const serviceId: AxiosResponse = yield select(
      (state: any) => state.booking.finalService.Id
    );

    const state: RootState = yield select((state: any) => state);
    const companyId: string = yield select((state: any) => state.company.data.Id);
    const time: TimeType = yield select((state: any) => state.booking.time);
    const customerFormValues: any = getFormValues("customerForm")(state);
    const customer = customerFormValues?.Customer;
    const authentication: ApplicationState['authenticate'] = yield select((state: any) => state.authenticate);
    // const 

    // @ts-ignore
    const response: { [key in string]: any } = yield call(api.getPromoCode, {
      CompanyId: companyId,
      ServiceId: serviceId,
      Date: time.From,
      CompanyRebateCodes: true,
      ...(customer && customer.Email && !authentication.isLoggedIn ? { CustomerEmail: customer.Email } : {}),
      ...action.payload,
    });

    // @ts-ignore
    yield put(actions.applyPromoCodeAsync.success(response));

    yield put(actions.calculatePriceAsync.request());
  } catch (error: any) {
    yield put(actions.applyPromoCodeAsync.failure(error));
  }
}

export function* removePromoCode(
  action: ReturnType<typeof actions.applyPromoCodeAsync.request>
) {
  try {
    yield put(actions.removePromoCodeAsync.success(action.payload));
    yield put(actions.calculatePriceAsync.request());
  } catch (error: any) {
    yield put(actions.applyPromoCodeAsync.failure(error));
  }
}

export function* calculatePrice(action: ReturnType<typeof actions.calculatePriceAsync.request>) {
  try {
      const serviceId: string = yield select((state: any) => state.booking.finalService.Id);
      const quantities: RootState['booking']['quantities'] = yield select((state: any) => state.booking.quantities);
      const companyId: string = yield select((state: any) => state.company.data.Id);
      const promoCodes: RootState['promoCodes']['data'] = yield select((state: any) => state.promoCodes.data);
      const time: RootState['booking']['time'] = yield select((state: any) => state.booking.time);
      const state: RootState = yield select((state: any) => state);
      const customerFormValues: any = getFormValues("customerForm")(state);
      const customer = customerFormValues?.Customer;

      const response: { [key in string]: any } = yield call(
        // @ts-ignore
        api.calculatePrice,
        {
          CompanyId: companyId,
          ServiceId: serviceId,
          Quantities: quantities,
          RebateCodeIds: promoCodes.map((promoCode: PromoCode) => promoCode.Id),
          ...(customer && customer.Email ? { CustomerEmail: customer.Email} : {}),
          Interval: {
            // @ts-ignore
            From: toDate(time.From).toISOString(),
            // @ts-ignore
            To: toDate(time.To).toISOString(),
          },
        }
      );
      yield put({
        type: 'UPDATE_BOOKING_QUANTITIES',
        payload: response
      });
      yield put(actions.calculatePriceAsync.success(response));
      yield put(actions.hidePromoCodeInput());
  } catch (error: any) {
      yield put(actions.applyPromoCodeAsync.failure(error));
      yield put(actions.calculatePriceAsync.failure(error));
  }
}

export function* createAccount(action: AnyAction) {
  try {
    const createAccountResponse: {[key in string]: any} = yield call(api.createAccount, action.payload);
    yield put(actions.createAccountAsync.success(createAccountResponse));
  } catch (err: any) {
    console.log(err);
    yield put(actions.createAccountAsync.failure(err));
  }
}

export function* fillCustomerForm(action: AnyAction) {
  let authentication = action.authenticate;
  const customerForm: {[key in string]: any} = yield select((state) => state?.form?.customerForm);
  const customerProfileValues: {[key in string]: any} = yield select(
    (state) => state.user.data?.CustomerProfile
  );

  if (!customerForm?.values?.Customer?.Firstname && customerProfileValues) {
    const CompanyUsers = authentication?.data?.Meta?.CompanyUsers
      ? JSON.parse(authentication?.data?.Meta?.CompanyUsers)
      : [];

    const companyId = authentication?.data?.Meta?.CompanyId;
    const customFieldsValues: any[] = CompanyUsers?.map
      ? CompanyUsers?.filter((el: any) => el.CompanyId === companyId)
          ?.map((el: any) => el.CustomFieldValues)
          ?.filter((el: any) => el && el.length > 0)

          ?.flatMap((el: any) => el)
      : [];

    let initialValues: { [index: string]: any } = {
      CustomerCustomFields: {},
    };

    customFieldsValues.forEach((customField) => {
      initialValues.CustomerCustomFields = {
        ...initialValues.CustomerCustomFields,
        [`_${customField.Id}`]: customField.Value,
      };
    });

    const formValues = { Customer: customerProfileValues };
    yield put({
      type: "@@redux-form/INITIALIZE",
      meta: {
        form: "customerForm",
        dirty: true,
      },
      payload: {
        ...formValues,
        ...initialValues,
      },
    });
  }
}

export function* startOver(action: AnyAction) {
  try {
    yield put({
      type: "RESET_FORM",
    });

    yield put(push({
      pathname: '/'
    }));
  } catch (error) {
    console.log(error);
  }

  window.location.reload();
}

export function* fixTooLong(action: AnyAction) {
  yield(put({ type: 'CLEAR_AUTHENTICATION' }));
  yield put(actions.fixLoadingTooLong.success());

  const params = new URLSearchParams(window.location.search);
  params.delete('session_state');
  params.delete('code');
  params.delete('state');

  window.location.search = params.toString();

  window.location.reload();
}

async function clearSession(clientId: string, refreshToken: string) {
  var myHeaders = new Headers();
  var urlencoded = new URLSearchParams();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  urlencoded.append('refresh_token', refreshToken);
  urlencoded.append("client_id", clientId);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
  };

  return fetch(
    keycloakConfig.logoutEndpoint,
    requestOptions
  );
}
