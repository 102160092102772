import { createSlice, createAsyncThunk, createListenerMiddleware } from '@reduxjs/toolkit';
import { TypedListener } from "@/store";
import api from "@/api/services";
import qs from 'qs';
import { decryptSymmetric, encryptionIVV, encryptionKey } from '@/misc/common';

export const STRIPE_AUTH_PATHNAME = '/stripe-auth';

interface Entity {
    accountId: string;
}

interface InitialState {
    entity: Entity | null;
    isLoading: boolean;
    error: any;
}

export const initialState: InitialState = {
    entity: null,
    error: null,
    isLoading: false,
};

// Middlewares
export const stripeAuthListener = createListenerMiddleware();
export const startStripeAuthListener = stripeAuthListener.startListening as TypedListener;


// Thunks
export const getAccount = createAsyncThunk(
    '/stripe/authenticate/',
    async (companyId: string , thunkApi) => {
        try {
            const encryptedAccountId: any = await api
              .getStripeAccount({ companyId })
              .then((res: any) => res.Results[0].StripeAccount);
            
              if (encryptionIVV && encryptionKey) {
                const accountId = await decryptSymmetric(
                  encryptedAccountId,
                  encryptionIVV,
                  encryptionKey
                );
                return thunkApi.fulfillWithValue(accountId as string);
              } else {
                throw new Error("Encryption keys not set");
              }
        } catch (error: any) {
            return thunkApi.rejectWithValue(error);
        }   
    }
)

// Slice

const stripeAuthSlice = createSlice({
    name: 'stripe',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getAccount.pending, (state, action) => {
            state.isLoading = true;
        });

        builder.addCase(getAccount.fulfilled, (state, action) => {
            state.entity = {
                accountId: action.payload
            };
            state.isLoading = false;
            state.error = false;
        });

        builder.addCase(getAccount.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    }
});


export default stripeAuthSlice;
