import React, { useEffect, useState } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { getAccount } from '@/reducers/stripeSlice';

type ExtraProps = {
  stripePromise: Promise<Stripe | null> | null;
};

export function WithStripe<T extends ExtraProps>(
  WrappedComponent: React.ComponentType<T>
) {
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null)
    const dispatch = useAppDispatch();
    const companyId = useAppSelector(s => s.company.data?.Id);
    const accountId = useAppSelector(s => s.stripe.entity?.accountId);

  useEffect(() => {
    if(companyId) {
        dispatch(getAccount(companyId))
    }
  }, [companyId]);

  useEffect(() => {
    if(accountId) {
        const _stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY, {
            stripeAccount: accountId
        });

        setStripePromise(_stripePromise);
    }
  }, [accountId])

  const ComponentWithExtraProps = (props: Omit<T, keyof ExtraProps>) => {
    return stripePromise ? (
      <WrappedComponent {...(props as T)} stripePromise={stripePromise} />
    ) : null;
  };
  
  return ComponentWithExtraProps;
}